// CourseGrid.js
import React from 'react';
import CourseCard from './CourseCard';
import '../css/CourseGrid.css'; // Import the CSS file for styling
import courses from '../utils/training.json'; // Ensure this path is correct

const CourseGrid = () => {
    return (
        <>
              <h1 className="text-center mb-4 custom-heading">Software Training Courses </h1>
    
        <div className="course-grid">
            
            {courses.map(course => (
                <CourseCard
                    key={course.id}
                    title={course.title}
                    description={course.description}
                    duration={course.duration}
                    contents={course.contents}
                />
            ))}
        </div>
    </> );
   
};

export default CourseGrid;
