import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../images/NEINUS05.png';
import 'bootstrap/dist/css/bootstrap.min.css';

const Header = () => {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <header className="header">
      <div className="container">
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="container-fluid">
            <Link className="navbar-brand" to="/" style={{ backgroundImage: `url(${logo})`, width: '200px', height: '50px', backgroundSize: 'contain', backgroundRepeat: 'no-repeat' }}></Link>
            <button className="navbar-toggler" type="button" onClick={toggleMenu}>
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className={`collapse navbar-collapse ${showMenu ? 'show' : ''} justify-content-end`}>
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link className="nav-link" to="/" onClick={() => setShowMenu(false)}>Home</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/about" onClick={() => setShowMenu(false)}>About Us</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/services" onClick={() => setShowMenu(false)}>Services</Link>
                </li>
                {/* <li className="nav-item">
                  <Link className="nav-link" to="/courses" onClick={() => setShowMenu(false)}>Courses</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/training" onClick={() => setShowMenu(false)}>Training</Link>
                </li> */}
                <li className="nav-item">
                  <Link className="nav-link" to="/courses" onClick={() => setShowMenu(false)}>Courses </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
}

export default Header;
