// CourseCard.js
import React, { useState } from 'react';
import '../css/CourseCard.css'; // Import the CSS file for styling

const CourseCard = ({ title, description, duration, contents }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const renderContents = (contents) => {
        return contents.map((item, index) => {
            if (typeof item === 'string') {
                return <li key={index}>{item}</li>;
            } else {
                const [key, value] = Object.entries(item)[0];
                return (
                    <li key={key}>
                        <strong>{key}:</strong>
                        <ul>
                            {value.map((subItem, subIndex) => (
                                <li key={subIndex}>{subItem}</li>
                            ))}
                        </ul>
                    </li>
                );
            }
        });
    };

    return (
        <div className="course-card">
            <h2 className="course-title bg-primary text-white p-2 rounded">{title}</h2>
            <p className="course-description">{description}</p>
            <p><strong>Duration:</strong> {duration}</p>
           
            {isExpanded && (
                <div className="course-details">
                    <h3>Course Contents:</h3>
                    <ul>
                        {renderContents(contents)}
                    </ul>
                    </div>
            )}
             <button onClick={toggleExpand} className="toggle-button">
                {isExpanded ? 'Read Less..' : 'Read More..'}
            </button>
        </div>
    );
};

export default CourseCard;
