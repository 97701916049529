import React from 'react';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/ImageCarousel.css'; // Custom CSS for additional styling

const ImageCarousel = ({ images }) => {
  return (
    <Carousel interval={5000} pause={false}> {/* Default slide transition */}
      {images.map((image, index) => (
        <Carousel.Item key={index}>
          <div
            className="carousel-image"
            style={{ backgroundImage: `url(${image.src})` }}
          >
            <div className="carousel-caption">
              <h3>{image.caption}</h3>
              <p>{image.description}</p>
            </div>
          </div>
        </Carousel.Item>
      ))}
    </Carousel>
  );
}

export default ImageCarousel;
