// src/App.js
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import About from './components/About';
import Services from './components/Services';
import TopBar from './components/Topbar';
import Terms from './components/Terms';
import PrivacyPolicy from './components/PrivacyPolicy';
import CourseGrid from './components/CourseGrid';
import WhatsAppIcon from './components/WhatsAppIcon'; 
import InstagramIcon from './components/InstagramIcon';
import { logPageView } from './analytics'; // Import analytics

import '@fortawesome/fontawesome-free/css/all.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

const App = () => {
  useEffect(() => {
    logPageView(); // Track page view
  }, []);

  return (
    <>
      <TopBar />
      <Header />
      <div className="container mt-4">
        <div className="row">
          <div className="col-md-12">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/services" element={<Services />} />
              <Route path="/courses" element={<CourseGrid />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            </Routes>
          </div>
        </div>
        <WhatsAppIcon phoneNumber="917200864223" /> {/* Replace with your WhatsApp number */}
        <InstagramIcon profileUrl="https://www.instagram.com/neinus_technologies/" /> {/* Instagram icon */}
      </div>
      <Footer />
    </>
  );
};

const AppWithRouter = () => (
  <Router>
    <App />
  </Router>
);

export default AppWithRouter;
