import React from 'react';
import servicesData from '../utils/servicesData.json'; // Import the services data

const Services = () => {
  return (
    <div className="container py-5">
     
      <div className="section-title text-center position-relative pb-3 mb-5 mx-auto" >
        <h2 className="mb-0">Custom IT Solutions for Your Successful Business</h2>
      </div>
      <div className="row gx-4 gy-5">
        {servicesData.map((service, index) => (
          <div className="col-lg-5 col-md-6" key={index}>
            <div className="service-item bg-light rounded text-center p-4 d-flex flex-column justify-content-center align-items-center">
              <div className="service-icon bg-primary rounded-circle d-flex align-items-center justify-content-center mb-4">
                <i className={service.icon}></i>
              </div>
              <h4 className="mt-2 mb-3">{service.title}</h4>
              <p className="mb-4">{service.description}</p>
              {/* <a className="btn btn-primary" href="#">{service.buttonText} <i className="bi bi-arrow-right ms-2"></i></a> */}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Services;
