import React from 'react';
import SocialIcons from './Socialicons';
const Footer = () => {
  return (
    <footer className="footer bg-lightblue text-white  mt-auto">
      <div className="padRL10 d-lg-block">
        <div className="row gx-0">
          <div className=" col-lg-10 text-center text-lg-start mt-2 mb-lg-0">
            <div className=" col-lg-8 d-inline-flex align-items-center ">
              <p>&copy; 2024 Neinus Technologies. All rights reserved.</p>   </div>
           
            <div className="col-lg-4 d-inline-flex align-items-center ">
              <p>  <a href="/terms" className="text-white ">Terms & Conditions</a> <span className='p-2'> | </span>
                <a href="/privacy-policy" className="text-white">Privacy Policy</a></p>
            </div>
          </div>
          <SocialIcons />
        </div>
      </div>
    </footer>
  )
}
export default Footer;
