// InstagramIcon.js
import React from 'react';
import '../css/InstagramIcon.css'; // Import the CSS file for styling

const InstagramIcon = ({ profileUrl }) => {
    return (
        <a
            href={profileUrl}
            className="instagram-icon"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Visit Instagram Profile"
        >
            <i className="fab fa-instagram"></i> {/* FontAwesome Instagram icon */}
        </a>
    );
};

export default InstagramIcon;
