import React from 'react';
import aboutusImg from '../images/banner01.jpg';
import ReactCountryFlag from "react-country-flag";
const AboutUs = () => {
  return (
    <div className="container-fluid py-2">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12 mb-4 mb-lg-0">
            <div className="section-title position-relative pb-3 mb-1">
              <h1 className="mb-0">Empowering Businesses through Innovative Solutions</h1>
            </div>
            <p className="mb-4">At Neinus, we are dedicated to providing reliable and efficient IT services to small and medium-sized businesses. Our mission is to empower our clients to achieve their goals and drive business growth by leveraging the latest technologies and best practices.</p>
            <p className="mb-4">With our expertise in software development and training, we offer comprehensive solutions tailored to meet the unique needs of each client. From custom software development to professional training programs, we are committed to delivering excellence in every aspect of our work.</p>
            <div className="row mb-3">
              <div className="col-md-6">
                <h5 className="mb-3"><i className="fa fa-check text-primary me-2"></i>Customized Solutions</h5>
                <h5 className="mb-3"><i className="fa fa-check text-primary me-2"></i>Expert Guidance</h5>
              </div>
              <div className="col-md-6">
                <h5 className="mb-3"><i className="fa fa-check text-primary me-2"></i>Continuous Support</h5>
                <h5 className="mb-3"><i className="fa fa-check text-primary me-2"></i>Transparent Communication</h5>
              </div>
            </div>
            <div className="d-flex align-items-center mb-4">
            
              <div className="card-text ps-3">
              <h5 className="mb-1 text-primary">Contact NEINUS for your Requirements: </h5>
                <div className="mb-2">
                  <ReactCountryFlag countryCode="IN" svg className="me-2" />
                  <strong>India</strong>
                  <p className="mb-1"><strong>Erode: </strong>SIBI Nest, 2nd Floor, 42/14  Perundurai Main Road, Thindal, Erode -  638012,, Tamil Nadu<br/>
                  <strong className='text-primary'> +91 7200864223 , +91 7200864623 </strong> </p>
                  <p><strong> Sathyamangalam: </strong> No 139/1A,Kamadhenu Nagar, Opposite To BIT College , Sathy Athani Road Bus Stop, Sathyamangalam - 638503 , Erode Dt, TamilNadu<br/>
                  <strong className='text-primary fw-bold'> +91 7200115836 , +91 9965618507</strong>  </p>
                </div>
              </div>
            </div>
            <a href="/services" className="btn btn-primary py-3 px-5 mt-3">Get Started</a>
          </div>
          <div className="col-lg-5 col-md-12">

            <img className="img-fluid rounded" alt="About Us" src={aboutusImg} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
